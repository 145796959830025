import { Canvg } from 'canvg';
import * as React from 'react';
import { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { CostingChart } from '../../templates/CostingChart/CostingChart';
import { toPng, toJpeg, getFontEmbedCSS } from 'html-to-image';
import * as d3 from 'd3';

import * as styles from '../../templates/CostingChart/CostingChart.module.scss';

export interface IChartFrameData{
    yearsShown: number;
    data: any[];
    labels: any[];
    width: number;
    chartId: string;
    height?: number;
    datasetMin?: number;
    datasetMax?: number;
}


export default function GraphSvg(){
    const [isRendering, setIsRendering] = useState<boolean>(false);
    const [nextChartData, setNextChartData] = useState<IChartFrameData>(null);
    const [chartData, setChartData] = useState<IChartFrameData>(null);
    const [chartId, setChartId] = useState<string>();

    const svgRef = useRef();

    useEffect(() => {
        window.addEventListener("message", (event) => {
            console.log(event);
            const { data }: { data: IChartFrameData} = event;
            const { labels, data:dataArr, yearsShown, width, height, chartId, datasetMax, datasetMin } = data;

            if(data && chartId){
                setIsRendering(true);
                setChartId(chartId);
                renderChart(labels, dataArr, yearsShown, width, height, datasetMin, datasetMax);
            }
        });

    },[]);

    const onGraphRenderComplete = async () => {
            console.log("Rendering complete");
            const chart = document.getElementsByTagName("svg")[0];
            // below doesn't work because it doesn't include the CSS styles - maybe revisit //
            // const svgString = new XMLSerializer().serializeToString(chart);
            // var decoded = decodeURIComponent(encodeURIComponent(svgString));
            // var base64 = window.btoa(svgString);
            // var imgSrc = `data:image/svg+xml;base64,${base64}`;
    
            // var svg = new Blob([data], {type: 'image/svg+xml'});
            // var url = window.URL.createObjectURL(svg);
    
            const fontEmbedCss = await getFontEmbedCSS(chart as any);
    
            toPng(chart as any, { backgroundColor: 'white', skipFonts: true, quality: 100 }).then((dataUri) => {
                window.parent.postMessage({
                    chartId, 
                    dataUri
                },"*");
                
                setIsRendering(false);
            });
    }

    const renderChart = (dataLabels: string[], dataArray: any[], yearsToRender: number = 3, width: number, height: number, datasetMin?: number, datasetMax?: number) => {
        const [dataSetLabel, ...restOfData] = dataArray; // The first column is the dataset label

        const parsedLabels = dataLabels.map((label) => label.replace(/\d{2}(\d{2})-(\d{2})/g, "$1-$2"));

        // split into two sets - short-term (current yr + 3) / medium-term (current yr + 10)
        const data = restOfData.slice(0, yearsToRender + 1); // slice array so that only 4 years are shown

        // if allDataSets prop is provided then we want a global scale based on this array of data arrays
        // const allDataPoints = allDataSets && allDataSets.length > 0 ? allDataSets.slice(1).map(v => v.slice(1, yearsToRender+2)).reduce((p,c) => [...p,...c], []).filter(v => !isNaN(parseFloat(v as string))) : null;
        const maxVal = d3.max(datasetMax ? [datasetMax, 0] : [...data, 0], (d) => parseFloat(d)); // get min/max values for use later
        const minVal = d3.min(datasetMin ? [datasetMin] : data, (d) => parseFloat(d));

        const maxBarWidth: number = 30;
        const minBarHeight: number = 5;

        console.log("MIN/MAX", minVal, maxVal, data);

        const svg = d3.select(svgRef.current);
        svg.selectAll("g").remove(); // Clear any existing data - this handles resizes

        svg.attr("width", width)
            .attr("height", height);        

        // xScale should be a band distribution based on the number of points
        const xScale = d3.scaleBand()
            .domain(data.map((_, i: number) => parsedLabels[i+1].toString())) // domain here is used to identify the x axis points, so we're going to use the data labels to make the rendering of the axis easier later
            .range([50, width]) // 20px padding either side to allow for the axis labels
            // .range([0, width]) // 20px padding either side to allow for the axis labels
            .padding(0.2); // % padding between items

        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0); // uses the data domain labels defined above

        svg.append("g")
            .attr("id", "chartXAxis")
            .call(xAxis)
            .selectAll("text")
            .classed(styles.xAxisLabel, true)
                // .attr("dx", "-0.75em")
                .attr("y", 0)
                .attr("dy", "1.6em")
                // .attr("transform","rotate(-55)")
                .style("text-anchor", "middle")
                .style("font-size", "10px");

        const xAxisTextNodes = Array.from(document.querySelectorAll('#chartXAxis text')); // select just the text nodes
        const maxXAxisLabelHeight = d3.max(xAxisTextNodes.map(v => v.getBoundingClientRect().height)) // get the height of the largest label, this is so we can position the rest of the graph above the axis
        
        const xAxisY = height - (maxXAxisLabelHeight + 20); // allows an additional 20px of space between the axis and the bottom of the SVG
        
        svg.select("#chartXAxis")
            .attr("transform", `translate(0, ${xAxisY})`); // position the axis at the bottom
        
            
        const yScaleInitial = d3.scaleLinear() // yScale needs to be relative to the highest/lowest values in the dataset
            .domain([(minVal < 0 ? minVal : 0) * 1.4, (maxVal * 1.4)]) // ensure 20% of headroom at the top of the graph no matter the scale and allow for negative values
            .range([xAxisY, 34]); // allow for the X axis labels at the bottom of the graph
            
        // Prevent negative values overlapping the axis when they're really small compared to the positive values
        const addedBottomPadding = xAxisY - yScaleInitial(0) < minBarHeight ? minBarHeight + 5 : 0;
        console.log(`Y SCALE 0 ==> ${ yScaleInitial(0) } <<>> ${ xAxisY }`)

        const yScale = yScaleInitial.range([xAxisY - addedBottomPadding, 34]);
        // the ticks param below sets the number of points to show in the y axis
        const yAxis = d3.axisLeft(yScale).ticks(height / 48);

        svg.append("g") //append the Y axis and set the properties
            .attr("id", "chartYAxis")
            .call(yAxis)
            .select("path")
                .attr("stroke","none");

        svg.select("#chartYAxis") // select Y axis labels and apply a CSS class + reposition them
            .selectAll("text")
                .classed(styles.yAxisLabel, true)
                // .classed(styles.yAxisLabel, (d) => d == 0 ? false : true)
                // .classed(styles.yAxisZeroLabel, (d) => d == 0 ? true : false)
                // .attr("x", "0.25em")
                .attr("x", "0")
                // .attr("dy", "-0.5em")
                .attr("dy", "-6")
                .attr("dx", 46)
                .style("text-anchor", "end")
                .style("font-size", "10px");
                
        svg.select("#chartYAxis") // add gridlines
            .selectAll("line")
                .classed(styles.yAxisGridLine, true)
                .attr("x2", width) // set the end point - by default x is 0 so this makes it run from left to right
                .attr("x1", 50);

        // append y axis unit label
        svg.append("g")
            .append("text")
            .text("$ million")
            .attr("x", (d: any) => ((height - 100) / 2) * -1)
            .attr("y", 10)
            .classed(styles.yAxisUnitLabel, true);

        const bars = svg.append("g")
            .selectAll("path")
            .data(data)
            .enter()
                .append("g")
                    .classed(styles.dataBarContainer, true);
            
        bars.append("path")
            .classed(styles.dataBar, true)
            .attr("d", (d, i) => {
                if(d == 0){
                    return 0;
                }
                const yZero = yScale(0);
                //console.log(`${d} -> y = ${yScale(d)}`)
                const maxWidth = maxBarWidth;
                const minHeight = d == 0 ? 0 : minBarHeight;
                const barWidth = Math.min(xScale.bandwidth(), maxWidth);
                const barXSpace = xScale.bandwidth() - barWidth; // if above is set to the maxWidth then we need to position the bars in the middle of their X space

                //const barHeight = Math.max(xAxisY - yScale(d as number), minHeight); // height
                // below line is trying to account for negative values - it needs more work...
                // console.log(`yzero:`, yScale(0))
                const yPosition = yScale(d as number);
                const isNegative = yPosition > yZero;
                // const barHeight = Math.max(isNegative ? yPosition - yZero : yZero - yPosition , minHeight); // height
                const barHeight = Math.max(Math.abs(yZero - yPosition), minHeight); // height
                const startX = xScale(parsedLabels[i+1].toString()) + (barXSpace / 2); // starting x position - start at left hand side
                const startY = yScale(0); // starting y position - start at axis 0 position

                const borderRadius = Math.min(barWidth / 2, barHeight); // to get rounded ends

                const path = d3.path(); // initialise new path

                // a --- b
                
                // These should be changed to functional functions down the track but basically just groups these bits of code for the time being.
                const drawBar_positive_arc = () => {
                    // positive bar draws from axis up
                    path.moveTo(startX, startY); // move to start position
                    path.lineTo(startX, Math.min(startY + borderRadius, startY - barHeight + borderRadius)); // left
                    // path.arc(startX + borderRadius, Math.min(startY + borderRadius, startY - barHeight + borderRadius), borderRadius, 0, Math.PI, true); // top arc
                    path.arc((startX + (barWidth/2)), Math.min(startY + borderRadius, startY - barHeight + borderRadius), borderRadius, 0, Math.PI, true); // top arc

                    path.lineTo(startX + barWidth, Math.min(startY + borderRadius, startY - barHeight + borderRadius)); // top
                    path.lineTo(startX + barWidth, startY); // right
                    path.lineTo(startX, startY); // bottom
                }
                
                const drawBar_negative_arc = () => {
                    // Negative bar draws from axis down
                    path.moveTo(startX, startY); // move to start position
                    path.lineTo(startX + barWidth, startY); // top
                    path.lineTo(startX + barWidth, Math.max(startY + barHeight - borderRadius, startY - borderRadius)); // right
                    // path.arc(startX + borderRadius, Math.max(startY + barHeight - borderRadius, startY - borderRadius), borderRadius, Math.PI, 0, true) // bottom arc
                    path.arc(startX + (barWidth/2), Math.max(startY + barHeight - borderRadius, startY - borderRadius), borderRadius, Math.PI, 0, true) // bottom arc
                    path.lineTo(startX, Math.max(startY + barHeight - borderRadius, startY - borderRadius)); // bottom
                    path.lineTo(startX, startY); // left
                }

                const drawBar_positive_flat = () => {
                    // positive bar draws from axis up
                    path.moveTo(startX, startY); // move to start position
                    path.lineTo(startX, Math.min(startY, startY - barHeight)); // left
                    path.lineTo(startX + barWidth, Math.min(startY, startY - barHeight)); // top
                    path.lineTo(startX + barWidth, startY); // right
                    path.lineTo(startX, startY); // bottom
                }
                
                const drawBar_negative_flat = () => {
                    // Negative bar draws from axis down
                    path.moveTo(startX, startY); // move to start position
                    path.lineTo(startX + barWidth, startY); // top
                    path.lineTo(startX + barWidth, Math.max(startY + barHeight, startY)); // right
                    path.lineTo(startX, Math.max(startY + barHeight, startY)); // bottom
                    path.lineTo(startX, startY); // left
                }

                if(isNegative){
                    drawBar_negative_flat();
                }
                else{
                    drawBar_positive_flat();
                }

                return path.toString();
            });

        const labelRandomIdentifier = Math.random().toString(36).substring(8);

        bars.append("text") // add data labels
            .text((d) => d == 0 || isNaN(parseFloat(d)) ? "" : parseFloat(parseFloat(d).toFixed(1)).toLocaleString())
            // .attr("id", (d) => `${labelRandomIdentifier}-dataLabel-${d}`)
            .attr("x", (d, i) => xScale(parsedLabels[i+1].toString()) + (xScale.bandwidth() / 2))
            .attr("y", (d) => {
                const isNegative = Math.sign(d) === -1;
                const minHeight = d == 0 ? 0 : 5;
                const barHeight = isNegative ? Math.max(Math.abs(yScale(d as number)), yScale(0) + minHeight) : Math.min(Math.abs(yScale(d as number)), yScale(0) + minHeight);
                const defaultLocation = (isNegative ? barHeight + 15 : barHeight - 8);

                return defaultLocation;
            })
            .attr("dy", (d, i, arr) => {
                const isNegative = Math.sign(d) === -1;
                const minHeight = d == 0 ? 0 : 5;
                const barHeight = isNegative ? Math.max(Math.abs(yScale(d as number)), yScale(0) + minHeight) : Math.min(Math.abs(yScale(d as number)), yScale(0) + minHeight);
                const defaultLocation = (isNegative ? barHeight + 15 : barHeight - 8);
                
                if(i !== 0){
                    console.log("CHECKING COLLISIONS");
                    const prevLabelBoundingBox = arr[i-1].getBBox();
                    const prevBBoxHeight = prevLabelBoundingBox.height && prevLabelBoundingBox.height > 0 ? prevLabelBoundingBox.height : 12
                    const prevLabelRX = prevLabelBoundingBox.x + (prevLabelBoundingBox.width * 0.8);
                    const thisLabelLX = (xScale(parsedLabels[i+1].toString()) + (xScale.bandwidth() / 2));
                    const prevLabelTop = prevLabelBoundingBox.y;
                    const prevLabelBottom = prevLabelBoundingBox.y + (prevBBoxHeight * 0.8);
                    const thisLabelBottom = defaultLocation; // y position is the BOTTOM of the text
                    const thisLabelTop = defaultLocation - prevBBoxHeight // assume labels are generally the same height

                    if(
                        prevLabelRX >= thisLabelLX && // check if labels will overlap on x axis
                        ((thisLabelTop <= prevLabelBottom && thisLabelTop >= prevLabelTop) || // top is between the top and bottom of previous label
                        (thisLabelBottom <= prevLabelBottom && thisLabelBottom >= prevLabelTop)) // or bottom is between top and bottom of previous label
                    ){
                        console.log("TOP", d, i, thisLabelTop, prevLabelTop, prevLabelBottom, Math.sign(d));
                        console.log("BOTTOM", d, i, thisLabelBottom, prevLabelTop, prevLabelBottom);
                        
                        // const adjustedLocation = Math.sign(d) === -1 ? (defaultLocation + prevLabelBoundingBox.height - 10) : (defaultLocation - prevLabelBoundingBox.height + 10);
                        const adjustedLocation = Math.sign(d) === -1 ? (prevLabelBoundingBox.y + defaultLocation) : (prevLabelTop - defaultLocation);
                        console.log("ADJUSTED", adjustedLocation, xAxisY, yScale(0), yScale(d));
                        // check that the data labels won't overlap the bottom axis
                        if(adjustedLocation > (xAxisY -15)){
                            // relocate label to the other side of the 0 axis
                            return yScale(0) - yScale(d) -20;
                            // return yScale(0) - 5;
                        }

                        return adjustedLocation;
                    }

                }
                // Check negative values don't overlap the bottom axis
                console.log("LOCATIONS = ", defaultLocation, xAxisY);
                if(defaultLocation > (xAxisY)){
                    console.log("IS NEGATIVE");
                    return (-1 * (defaultLocation - yScale(0))) -  6;
                }
                    
                return 0;
            })
            .classed(styles.dataLabel, true)
            .style("font-size", "10px");
        
        svg.append("g") // add the chart title
            .classed(styles.titleGroup, true)
            .append("text")
                .text(dataSetLabel)
                .attr("x", width /2)
                .attr("y", "1em")
                .attr("style", "font-size: 12px");

        // add dark gridline for 0 position
        svg.append("g")
            .append("line")
            .style("stroke", "black")
            .style("stroke-width", "1")
            .attr("x2", width)
            .attr("x1", 50)
            .attr("transform", `translate(0,${ yScale(0)} )`);

        onGraphRenderComplete();
    }

    return (
        <svg ref={ svgRef } />
    );
}